import { Navigate, Outlet } from 'react-router-dom'
import { useUser } from '../../context'

function AdminRoutes () {
    const { currentUser } = useUser();
    return (
        currentUser && currentUser.userInfo.type === "admin" ? <Outlet /> : <Navigate to="/" />
    )
}

export default AdminRoutes;