import React from 'react';

import './styles.css';

const VideoPlayer = ({ embedId }) => {
    return (
        <div className="video-player">
            <iframe
                src= {`https://www.youtube.com/embed/${embedId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}>
            </iframe>
        </div>
    )
}

VideoPlayer.defaultProps = {
    embedId: 'dQw4w9WgXcQ'
}

export default VideoPlayer;