import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { CountDown } from "../../components"
import { useNavigate } from "react-router-dom";

import LogoWithText from "../../assets/logoWithText.svg";
import { BandCard, JudgeCard } from "../../components"
import { BANDS2022 as bands, BANDS2022, JUDGES2022 as judges } from "../../utils"

import { useUser } from '../../context'

import { HomeLayout } from "../../layouts";

import './styles.css';

import Banner from "../../assets/MIKE0081.JPG"


function HeroSection() {
    const targetDate = new Date('October 15, 2023 19:30:00').getTime();
    let navigate = useNavigate();

    return (
        <div className='pb-2 home-top-section'>
            <div id="hide-when-small" className="fixed-button">
                <Button variant="primary" size="md" className="pastButton" onClick={() => { navigate('/archive') }}>
                    Previous Events
                </Button>
            </div>
            <div className="my-body">
                <Container fluid className="px-4 pt-5">
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <img
                                className="logo-img"
                                alt="Sawti Ilak Logo"
                                src={LogoWithText}
                            />
                        </Col>
                    </Row>

                    <Row className="pt-5">
                        <Col className="d-flex flex-column align-items-center">
                            <p className="h5 mx-md-5 text-center">Sawti Ilak, initiated by MEC Worship in 2021, offers church bands a unique opportunity to share songs they've composed for the glory of God. This platform serves as a means for churches to discover and utilize these heartfelt compositions within their own congregations. The selection process for these songs involves careful consideration of their alignment with biblical principles, musical quality, and language. Over a period of three months, participating bands receive professional training at the MEC Worship Center to ensure their exceptional performance during the event. Each band then gets professional recording for their songs, allowing a wider audience to share and enjoy them. Sawti Ilak stands as a testament to the power of music to foster spiritual connection and worship within the Christian community.</p>
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col className="d-flex flex-column align-items-center">
                            <h2 style={{ fontWeight: "700" }}>Prepare for Sawti Ilak 2023:</h2><h1 style={{ fontWeight: "700" }}>An Evening of Worship and Unity!</h1>
                            <h5 className="text-center mx-5 mt-3">The date is fast approaching! We cordially invite you to join us on the 15th of October at 7:00 PM at Theatre du Boulevard in Chiyah for an unforgettable Night of Worship led by exceptionally gifted teams representing various churches.</h5>
                            <h3 className="my-3" style={{ fontWeight: "700" }}>What to expect:</h3>
                            <h5 className="text-center mx-md-5">Sawti Ilak is not just an event; it's a gathering of fellow believers seeking to strengthen their faith and create connections.</h5>
                            <h5 className="text-center mx-md-5">Our participants have been hard at work creating brand-new songs, with the help of the MEC Worship Team, which will be showcased at the event.</h5>
                            <h5 className="text-center mx-md-5">Expect a lineup of exceptionally talented performers who will lead you in heartfelt worship and inspire you with their musical talents.</h5>
                            <h3 className="my-3" style={{ fontWeight: "700" }}>Event Details:</h3>
                            <div className="d-flex flex-row"><h5 className="mx-2" style={{ fontWeight: "700" }}>Date:</h5><p>October 15, 2023</p></div>
                            <div className="d-flex flex-row"><h5 className="mx-2" style={{ fontWeight: "700" }}>Time:</h5><p>7:00 PM</p></div>
                            <div className="d-flex flex-row"><h5 className="mx-2" style={{ fontWeight: "700" }}>Venue:</h5><p>Theatre du Boulevard | Forum, Chiyah</p></div>
                            <h5 className="text-center mt-3 mx-md-5">This event is open to all, and you are warmly invited.</h5>
                            <h5 className="text-center mx-md-5">Don't miss this opportunity to be part of an evening that celebrates faith, music, and unity. </h5>
                            <h5 className="text-center mx-md-5">Invite your friends and family!</h5>
                            <h5 className="text-center mt-3 mx-md-5" style={{ fontWeight: "800" }}>Stay tuned for further updates, and please make sure to mark your calendar.</h5>
                            <h5 className="text-center mx-md-5" style={{ fontWeight: "800" }}>We look forward to seeing you at Sawti Ilak 2023!</h5>

                        </Col>
                    </Row>
                    {targetDate > Date.now() &&
                        <>
                            <Row className="pt-5">
                                <Col className="d-flex justify-content-center">
                                    <p className="text-center h2">
                                        Sawti Ilak 2023 will take place on Oct. 15
                                    </p>
                                </Col>
                            </Row>
                            <Row className="pt-3">
                                <Col className="d-flex justify-content-center">
                                    <p className="display-1 text-center heavy-text">
                                        Stay Tuned
                                    </p>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row className="text-center">
                        <CountDown targetDate={targetDate} />
                        {/*<h1>The Winner of the 2022 Sawti Ilak is:</h1> */}
                    </Row>
                    {/*<Row className="ps-4">
                        <BandCard {...bands[2]}/>
                    </Row>*/}
                    {targetDate > Date.now() && false &&
                        <Row>
                            <Col className="d-grid pt-3">
                                <Button href="https://middleeastcenter.org/sawtiilak" target="_blank" variant="primary" size="md" className="reserve-spot">
                                    Reserve Your Spot!
                                </Button>
                            </Col>
                        </Row>
                    }
                </Container>``
            </div>
        </div>
    )
}

function CurrentSawtiIlak() {

    const { currentUser } = useUser();
    function getBandFromId(id) {
        const bandInfo = BANDS2022.find((band) => {
            return band.id === id
        })
        return bandInfo.songName
    }

    return (
        <Container className="band-and-stuff pb-5 pt-5 mt-0 mb-0">
            {currentUser && currentUser.userInfo && currentUser.userInfo.firstName &&
                <>
                    <Row>
                        <Col className="text-center">
                            <h1 className="bold-text">
                                Welcome {currentUser.userInfo.firstName}
                            </h1>
                        </Col>
                    </Row>
                    {currentUser.userInfo.hasVoted &&
                        <Row>
                            <Col className="text-center">
                                <h5>
                                    You voted for {getBandFromId(currentUser.userInfo.voteId)}
                                </h5>
                            </Col>
                        </Row>
                    }
                </>
            }
            <Row id="bands"></Row>
            {
                bands.sort((a, b) => a.order - b.order).map((band, index) => {
                    if (index + 1 < bands.length) {
                        return <div key={`parent-${index}`}>
                            <BandCard key={`band-${index}`} {...band} />
                            <div key={`divider-${index}`} className='showWhenSmall thin-divider'></div>
                        </div>
                    } else {
                        return <BandCard key={`band-${index}`} {...band} />
                    }
                })
            }
            <Row id="judges" className="justify-content-center mb-5 mt-5">
                <h5 className="text-center">Judges</h5>
                <div className='divider'></div>
            </Row>
            {
                judges.map((judge, index) => {
                    return <JudgeCard key={`judge-${index}`} {...judge} />
                })
            }
        </Container>
    )
}

function SawtiIlak2023() {
    return (
        <>
            <img className="banner" style={{ width: "100%", objectFit: "cover" }} src={Banner} alt="sawti ilak 2023 poster" />
            <Container className="mb-0 pb-2">
                <Row>
                    <Col className="d-flex flex-column justify-center mt-3">
                        <h5 style={{ fontWeight: 700 }}>Sawti Ilak 2023: A Night of Worship and Unity!</h5>
                        <p>We are overjoyed to share with you the touching and motivational recap of the MEC (Middle East Center)-organized Sawti Ilak 2023 event. It was an unforgettable night of worship, unity, and talent that left a blessed mark on our hearts.</p>
                        <br />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <h6 style={{ fontWeight: 700, color: "white" }}>Sawti Ilak 2023 Highlights:</h6>
                </Row>
                <p className="pt-2">Worship Night: The event kicked off with a powerful worship night where voices and hearts were lifted in unison, celebrating the boundless love and grace of our Savior.</p>
                <p className="pt-2">Churches United: Sawti Ilak was a testament to the strength of unity among churches. Congregations from various denominations came together to worship as one, fostering a sense of togetherness and harmony.</p>
                <p className="pt-2">Original Worship Songs: Bands took their creativity to new heights by composing and performing their own worship songs, adding a personal touch to the night's worship. The MEC Worship team also sang a medley featuring songs from previous Sawti Ilak events.</p>
                <p> Stay tuned as we will be uploading the songs soon!</p>
                <p className="pt-2">Trophy of Achievement: Each church band participating in Sawti Ilak 2023 was awarded a prestigious disk trophy as a symbol of their dedication and commitment to worship through music.</p>
                <br />

                <h5 className="mt-3" style={{ fontWeight: 700 }}>Get Ready for Next Year:</h5>
                <p>As we bask in the afterglow of this remarkable event, we can't help but look ahead with excitement. Sawti Ilak 2024 promises to be even more exceptional, with new opportunities for growth, unity, and worship.</p>
                <br />
                <p style={{ fontWeight: 700 }}>Stay updated for new projects at MEC worship by following our social media platforms.</p>
                <br />
                <p>We praise God for making Sawti Ilak 2023 a resounding success. Together, we will continue to celebrate the power of music and unity in our faith communities.</p>
                <br />
            </Container>
        </>
            )
}

            function Home() {

    return (
            <HomeLayout>
                <SawtiIlak2023 />
            </HomeLayout>
            );
}

            export default Home;
