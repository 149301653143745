import React, {useEffect, useRef} from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button"

import { BandCard, JudgeCard } from "../../components"
import { BANDS2021 as bands, JUDGES2021 as judges } from "../../utils";

import { MainLayout } from '../../layouts';

import { useNavigate, useLocation } from "react-router-dom";

import './styles.css';



function SawtiIlak2021 () {

    let navigate = useNavigate();
    let location = useLocation();

    const bandsRef = useRef();
    const judgesRef = useRef();

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        if (location.hash) {
            if (location.hash === '#bands') {
                scrollToRef(bandsRef);
            }
            else if (location.hash === '#judges') {
                scrollToRef(judgesRef);
            }
        }
    }
    , [location.hash]);

    return (
        <MainLayout>
            <div className="py-2">
                <div className="bands-backdrop">
                    <Container className='pb-3'>
                        <Row>
                            <Col xs={9} className="d-flex">
                                <h1 className="sawti-ilak-2021 text-center">
                                    Sawti Ilak 2021
                                </h1>
                            </Col>
                            <Col xs={3} className="d-flex justify-content-center align-items-center">
                                <Button variant="light" onClick={() => { navigate('/sawti-ilak-pictures/2021') }} className={'pictures-button'}>
                                    Pictures
                                </Button>
                            </Col>
                        </Row>
                        <Row ref={bandsRef}  className="d-flex justify-content-center pt-2">
                            <div className='divider'></div>
                        </Row>
                        {bands.map((band, index) => {
                            if (index <= 1) {
                                return (
                                    <div key={`parent-${index}`}>
                                        <BandCard key={`band-${index}`} {...band} />
                                        <Row key={`divider-parent-${index}`} className="d-flex justify-content-center pt-2">
                                            <div key={`divider-${index}`} className='thin-divider'></div>
                                        </Row>
                                    </div>
                                )
                            } else if (index === 2) {
                                return (
                                    <div key={`parent-${index}`}>
                                        <BandCard key={`band-${index}`} {...band} />
                                        <Row key={`divider-parent-${index}`} className="d-flex justify-content-center pt-2">
                                            <div key={`divider-${index}`} className='divider'></div>
                                        </Row>
                                    </div>
                                )
                            } else if (index === bands.length-1) {
                                return (
                                    <BandCard key={`band-${index}`} {...band} />
                                )
                            }
                            else {
                                return (
                                    <div key={`parent-${index}`}>
                                        <BandCard key={`band-${index}`} {...band} />
                                        <Row key={`divider-parent-${index}`} className="d-flex justify-content-center pt-2">
                                                <div key={`divider-${index}`} className='thin-divider'></div>
                                        </Row>
                                    </div>
                                )
                            }
                        })}
                        <Row className='py-2 mx-1 sm-hidden'>
                            <Col md={4} className='d-flex align-items-center justify-content-center'>
                                <p>Judges</p>
                            </Col>
                        </Row>
                        <Row ref={judgesRef} className="d-flex justify-content-center pt-2">
                            <div className='divider'></div>
                        </Row>
                        {judges.map((judge, index) => {
                            if (index === judges.length-1) {
                                return (
                                    <JudgeCard key={`judge-${index}`} {...judge} />
                                    )
                            } else {
                                return (
                                    <div key={`parent-${index}`}>
                                        <JudgeCard key={`judge-${index}`} {...judge} />
                                        <Row key={`judge-divider-parent-${index}`} className="d-flex justify-content-center pt-2">
                                            <div key={`judge-divider-${index}`} className='thin-divider'></div>
                                        </Row>
                                    </div>
                                )
                            }
                        })}
                    </Container>
                </div>
            </div>
        </MainLayout>
    );
}

export default SawtiIlak2021;
