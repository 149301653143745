import ChurchOfGodDora from "../../assets/sawtiIlak2022/bandPhotos/ChurchofGodDora.jpg";
import LifeCenter from "../../assets/sawtiIlak2022/bandPhotos/LifeCenter.jpg";
import LifeCenter1 from "../../assets/sawtiIlak2022/bandPhotos/LifeCenter1.jpg";
import SpringOfLifeChurch from "../../assets/sawtiIlak2022/bandPhotos/SpringofLifeChurch.jpg";
import TrinityBaptistChurch from "../../assets/sawtiIlak2022/bandPhotos/TrinityChurch.jpg";

import Andre from '../../assets/sawtiIlak2022/judgePhotos/Andre.jpg';
import Avo from '../../assets/sawtiIlak2022/judgePhotos/Avo.jpg';
import Gaby from '../../assets/sawtiIlak2022/judgePhotos/Gaby.jpg';
import Sabine from '../../assets/sawtiIlak2022/judgePhotos/Sabine.jpg';

const BANDS2022 = [
    {
        order: 3,
        id: "vPS7K2Q7j9oo6ycmhpLe",
        bandName: "Church Of God (Dora)",
        songName: "قد قام يسوع غالبا",
        bandImage: ChurchOfGodDora,
        lyrics:
        <>
            <br />
            جاء إلهي إلى العالم جاء لكي يفديه
            <br />
            ليضع نفسه عنه من مرضه يشفيه
            <br />
            من سلطان الخطيئة من أبواب الجحيم
            <br />
            حمل الصّليب عنه ليخلّصه ويحييه
            <br />
            <br />
            قد قام يسوع غالبًا منتصرًا في الصّليب
            <br />
            أين شوكتك يا موت قد غلبك الحبيب
            <br />
            لم يمسكه الموت أبدًا لم يدركه الظّلام
            <br />
            فإلهي نور العالم هو رئيس السّلام
            <br />
            <br />
            تألّم مجرّبًا ليعين المجرّبين
            <br />
            كان يجول يصنع خيرًا ويريح المتعبين
            <br />
            صلبوه وعلّقوه بين الآثمين
            <br />
            فقام في اليوم الثّالث وجرّد السّلاطين
            <br />
            <br />
            اختارني عيّنني لأكون ابنًا له
            <br />
            قدّيسا بلا لوم يحضرني أمامه
            <br />
            غسّلني طهّرني بقوّة دمه
            <br />
            أزال عنّي كلّ ذنبي، ألبسني رداءه
        </>,
        linkToMp4: '',
        position: '1st Place',
        author: "رانيا خوري",
        composer: "جورج موسان",
        year: 2022
    },
    {
        order: 6,
        id: '7P8qjnm2kDm8jMoxBcWW',
        bandName: "Spring Of Life Church",
        songName: "ربي أنت كنزي الشخصي",
        bandImage: SpringOfLifeChurch,
        lyrics:
        <>
            <br />
                رَبّي أنتَ كَنْزيَ الشَّخصي
            <br />
                يا إِلَهيَ الغالي
            <br />
            <br />
                أنتَ رَبّي أنتَ حبيبي
            <br />
                أسْتَسْلِمُ لكَ مِنْ كُل قلبي
            <br />
            <br />
                أُحِبُّكَ
            <br />
                أَعْبُدُكَ
            <br />
                بروحِكَ أحيا ربّي يسوع
            <br />
            <br />
                أنتَ الإلهُ الحَيُّ والرَحوم
            <br />
                مِنْ أَجْلي جِئْتَ إلى العالَم
            <br />
            <br />
                مِن أَجْلي قَدَّمْتَ حياتَكَ
            <br />
                غَلَبْتَ الموتَ وارتَفَعْتَ
            <br />
            <br />
                أُحِبُّكَ
            <br />
                أَعْبُدُكَ
            <br />
                بروحِكَ أحيا ربّي يسوع
            <br />
            <br />
        </>,
        linkToMp4: '',
        position: '2nd Place',
        author: 'جاك خاتشادوريان',
        composer: 'جاك خاتشادوريان',
        year: 2022
    },
    {
        order: 2,
        id: "jpYlbC0y5jDB2l2aAE31",
        bandName: "Church Of God (Life Center)",
        songName: "ما في بقلبي غاية",
        bandImage: LifeCenter,
        lyrics:
        <>
            <br />
                ما في بقلبي غاية
            <br />
                بصلّيلك من دون ملل
            <br />
                يسوع إنت البداية
            <br />
                إنت المجد يللي أكتمل
            <br />
            <br />
                عم بسجدلك ليل نهار
            <br />
                تا تحضنّي بهالمشوار
            <br />
                قربانك أجمل تذكار
            <br />
                عم يهدي سنيني الأمل
            <br />
            <br />
                يا ماحي حزن هالدنيي
            <br />
                تحت صليبك ينحني
            <br />
                إلهي فيك بغتني
            <br />
                قدامك بسجد يا حمل
            <br />
            <br />
                يسوع يا أحلى كلمة
            <br />
                مزروع بقلبي نغمة
            <br />
                إنت العطا والنعمة
            <br />
                كرمالي العذاب احتمل
        </>
        ,
        linkToMp4: '',
        position: '3rd Place',
        author: "طليع خليل",
        composer: "طليع خليل",
        year: 2022
    },
    {
        order: 4,
        id: "YU8UvJxb0wxViRBtNQqQ",
        bandName: "Trinity Church",
        songName: "سأشفى من مرضي",
        bandImage: TrinityBaptistChurch,
        lyrics:
        <>
            <br />
            سأُشفى من مرضي سأبرأ من علّتي)
            <br />
            2(لن أعودَ لن أعود لحياة الظلمةِ
            <br />
            هل يكفيك سجودي أو تنفع دموعي)
            <br />
            2(وأنا، أنا وعودي حتى الآن لم أفِ بها
            <br />
            <br />
            سأنمو بالنّعمة أتقدّم بالحكمةِ)
            <br />
            2(لن أقبل بعد الآن أن أحيا بخطيّتي
            <br />
            هل يكفيك سجودي أو تنفع دموعي)
            <br />
            2(وأنا، أنا وعودي حتى الآن لم أفِ بها
            <br />
            <br />
            بالموت خلّصتني بإسمي دعوتني)
            <br />
            2(أتبعك إني لك يا ربّي بجملتي
            <br />
            هل يكفيك سجودي أو تنفع دموعي)
            <br />
            2(وأنا، أنا وعودي حتى الآن لم أفِ بها
            <br />
            <br />
            سامحني يا حبيبي قد تهت في الطريقِ
            <br />
            والآن إنّي أرجع... ظلّلني بصليبك
            <br />
         </>
        ,
        linkToMp4: '',
        position: '',
        author: "ألفرد طويل",
        composer: "مارك مؤدّب",
        year: 2022
    },
    {
        order: 1,
        id: "ZO6S9JN5IOGxYyFZ2mxP",
        bandName: "Trinity Church",
        songName: "لو لم تكن معي",
        bandImage: TrinityBaptistChurch,
        lyrics:
        <>
            <br />
            لو لم تكن معي لغرِقتُ في دموعي
            <br />
            لو لم تمسك يدي لم أضمن رجوعي
            <br />
            قد مُتَّ من أجلي جدّدت ينبوعي)
            <br />
            2(أتبعك يا ربّي يا حبيبي يا يسوعي
            <br />
            يا حبيبي يا يسوعي
            <br />
            <br />
            ها صوت النّداءِ يدعوك يا صديقي
            <br />
            أسرع لا تقسّي يسوع في الطّريق
            <br />
            سيلاقينا الفادي بالمجد عن قريب)
            <br />
            2(وجهاً لوجه نراه ونعيش معه في سماه
            <br />
            ونعيش معه في سماه
            <br />
            <br />
            للدرب الأكمل أدعو كلّ خرافي
            <br />
            ومن ليس معي لن يحظى بنجاتي
            <br />
            هيّا هبّو قوموا حمداً للمنجّي)
            <br />
            2(لكي لا تدانوا اُدنوا من الهدبِ
            <br />
            اُدنوا من الهدبِ
        </>,
        linkToMp4: '',
        position: '',
        author: "ألفرد طويل",
        composer: "مارك مؤدّب",
        year: 2022
    },
    {
        order: 6,
        id: "nUedkGIy84EEvOyqdQoK",
        bandName: "Church Of God (Dora)",
        songName: "وسط الظروف",
        bandImage: ChurchOfGodDora,
        lyrics:
        <>
            <br />
            وسط الظّروف، وسط الضّيقات، وسط المشاكل
            <br />
            بتبقى يسوع، عنّي يسوع، عنّي بتحارب
            <br />
            <br />
            انت يسوعي حبيبي بتمليني بروحك بتحييني
            <br />
            بتدافع عنّي وبتحميني وبقوّة دمّك بتغطّيني
        </>,
        linkToMp4: '',
        position: '',
        author: "القس جوزيف زعرب",
        composer: "القس جوزيف زعرب",
        year: 2022
    },
    {
        order: 7,
        id: "sHlCQLHtcyzeRslJY4R9",
        bandName: "Church Of God (Life Center)",
        songName: "يسوع واقف على الباب",
        bandImage: LifeCenter1,
        lyrics:
        <>
            <br />
            يسوع واقف على الباب ويقرع
            <br />
            من سمع صوته فليفتح
            <br />
            <br />
            نداؤه الأخير تعالوا يا متعبين
            <br />
            من سمع صوته فليفتح
            <br />
            <br />
            الهنا الحبيب مجيئه قريب
            <br />
            ونفخة بوق سيسمع
            <br />
            <br />
            ملكه يظهر ببهاء مجده
            <br />
            ووجهه يلمع من فائق نوره
            <br />
            <br />
            تعالوا يا خطاة لواهب الحياة
            <br />
            فدمه الكريم يعطي النجاة
            <br />
            <br />
            الهنا الحبيب مجيئه قريب
            <br />
            ونفخة بوق سيسمع
        </>,
        linkToMp4: '',
        position: '',
        author: "شربل حدشيتي",
        composer: "شربل حدشيتي",
        year: 2022
    }
]

const JUDGES2022 = [
    {
        judgeName: 'Andreh Tawileh',
        judgePicture: Andre,
        judgeDescription: 'Leads a music school and a studio, and has several diplomas in guitar and music'
    },
    {
        judgeName: 'Gaby El Aouad',
        judgePicture: Gaby,
        judgeDescription: 'Pastor, Song Writer, Worship Leader, Musician',
    },
    {
        judgeName: 'Sabine Jarawan',
        judgePicture: Sabine,
        judgeDescription: 'Piano instructor at the national conservatory',
    },
    {
        judgeName: 'Avo Kazazian',
        judgePicture: Avo,
        judgeDescription: 'Songwriter and Piano Instructor',
    }
]


export { BANDS2022, JUDGES2022 }
