import { Home, Archive, SawtiIlak2021, SawtiIlak2022, SawtiIlak2023, Song, Login, Signup, Admin, Pictures } from '../../pages';

const HOME_ROUTE = {
    label: 'Home',
    path: '/',
    component: <Home />,
    protected: false
}

const ARCHIVE_ROUTE = {
    label: 'Archive',
    path: '/archive',
    component: <Archive />,
    protected: false
}

const SAWTI_ILAK_2023_ROUTE = {
    label: 'Sawti Ilak 2023',
    path: '/sawti-ilak-2023',
    component: <SawtiIlak2023 />,
    protected: false
}

const SAWTI_ILAK_2022_ROUTE = {
    label: 'Sawti Ilak 2022',
    path: '/sawti-ilak-2022',
    component: <SawtiIlak2022 />,
    protected: false
}

const SAWTI_ILAK_2021_ROUTE = {
    label: 'Sawti Ilak 2021',
    path: '/sawti-ilak-2021',
    component: <SawtiIlak2021 />,
    protected: false
}

const SAWTI_ILAK_2021_PICTURES_ROUTE = {
    label: 'Sawti Ilak 2021',
    path: '/sawti-ilak-pictures/:year',
    component: <Pictures />,
    protected: false
}

const SONG_ROUTE = {
    label: 'Song',
    path: '/song/:id',
    component: <Song />,
    protected: false
}

const LOGIN_ROUTE = {
    label: 'Login',
    path: '/login',
    component: <Login />,
    protected: false
}

const SIGNUP_ROUTE = {
    label: 'Signup',
    path: '/signup',
    component: <Signup />,
    protected: false
}

const ADMIN_ROUTE = {
    label: 'Admin',
    path: '/admin',
    component: <Admin />,
    protected: true
}

const ROUTES = [
    HOME_ROUTE,
    SAWTI_ILAK_2021_ROUTE,
    SONG_ROUTE,
    LOGIN_ROUTE,
    SIGNUP_ROUTE,
    ADMIN_ROUTE,
    ARCHIVE_ROUTE,
    SAWTI_ILAK_2021_PICTURES_ROUTE,
    SAWTI_ILAK_2022_ROUTE,
    SAWTI_ILAK_2023_ROUTE
];

export { ROUTES };
