import React from "react";

import AudioPlayer from 'react-h5-audio-player'

//import 'react-h5-audio-player/src/styles.scss';
//import './styles.scss';

function MusicPlayer ({ songSource }) {
    return (
        <AudioPlayer
            className="mscPlayer"
            src={songSource}
            customVolumeControls={[]}
            customAdditionalControls={[]}
        />
    )
}

export default MusicPlayer;
