import { createContext, useContext, useState, useEffect } from 'react';
import { signInWithPhoneNumber, signOut } from 'firebase/auth';
import { doc, getDoc, setDoc, onSnapshot, runTransaction } from 'firebase/firestore'
import { auth, db } from '../../api';

const UserContext = createContext ()

function useUser () {
    return useContext(UserContext)
}

function UserProvider ({children}) {
    const [currentUser, setCurrentUser] = useState();
    const [votingState, setVotingState] = useState(false);

    useEffect(() => {
        const retrievedUserInfo = localStorage.getItem('userData');
        if (retrievedUserInfo) {
            let info = JSON.parse(retrievedUserInfo)
            setCurrentUser(info)
        }
    },[])

    const voteRef = doc( db, 'admin', 'I1Co8QKLF1AqLcKeoAav' )
    onSnapshot( voteRef, (doc) => {
        const votingInfo = doc.data();
        if (votingInfo.voting !== votingState) {
            setVotingState(votingInfo.voting)
        }
    })

    async function login ( phoneNumber, verification ) {
            const response = await signInWithPhoneNumber ( auth, `+${phoneNumber}`, verification );
            return response;
    }

    async function verify ( code, confirmation, country ) {
        try {
            const response = await confirmation.confirm(code);
            localStorage.removeItem('loginVerification');
            return getUserInfo(response.user.uid, response.user.phoneNumber, country);
        } catch (err) {
            console.error(err)
            const errorMessage = err.toString();
            if (errorMessage === 'FirebaseError: Firebase: Error (auth/invalid-verification-code).') {
                return errorMessage
            } else {
                return undefined;
            }
        }
    }

    async function getUserInfo ( UID, phoneNumber, country ) {
        const docRef = doc( db, "users", UID )
        try {
            let docSnap = await getDoc (docRef);
            if (docSnap.exists()) {
                setCurrentUser( { 'UID': UID, "userInfo": docSnap.data() } )
                localStorage.setItem('userData',  JSON.stringify({ 'UID': UID, "userInfo": docSnap.data() }) )
                return true;
            } else {
                let userType;
                if (country.countryCode === 'lb') {
                    userType = 'local';
                } else {
                    userType = 'foreign';
                }
                setCurrentUser( { 'UID': UID, "userInfo": {'phoneNumber': phoneNumber, 'type': userType, 'country': country.name } })
                localStorage.setItem('userData', JSON.stringify({ 'UID': UID, "userInfo": {'phoneNumber': phoneNumber, 'type': userType, 'country': country.name } }))
                return false;
            }
        } catch (err) {
            console.error(err)
        }
    }

    async function createUser ({firstName, lastName, email}) {
        let userInfo = {
            'email': email,
            'firstName': firstName,
            'lastName': lastName,
            'type': currentUser.userInfo.type,
            'hasVoted': false,
            'phoneNumber': currentUser.userInfo.phoneNumber,
            'country': currentUser.userInfo.country,
            'voteId': null
        }
        try {
            let docRef = doc( db, 'users', currentUser.UID );
            await setDoc ( docRef, userInfo );
            setCurrentUser({ 'UID': currentUser.UID, 'userInfo': userInfo })
            localStorage.setItem('userData', JSON.stringify({ 'UID': currentUser.UID, 'userInfo': userInfo }))
            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    async function logout () {
        try {
            await signOut(auth);
            setCurrentUser(null);
            localStorage.clear();
        } catch (err) {
            console.log(err)
        }
    }

    async function vote (bandId) {
        const bandRef = doc(db, "bands", bandId);
        const userRef = doc(db, "users", currentUser.UID);

        try {
            await runTransaction (db, async (transaction) => {
                const bandSnap = await transaction.get(bandRef);
                const userSnap = await transaction.get(userRef);

                let bandInfo = bandSnap.data();
                let userInfo = userSnap.data();

                if ( !userInfo.hasVoted ) {
                    userInfo.hasVoted = true;
                    userInfo.voteId = bandId;

                    if ( userInfo.type === "foreign" ) {
                        bandInfo.foreignVotes = bandInfo.foreignVotes + 1;
                        bandInfo.foreignNames = [...bandInfo.foreignNames, currentUser.UID];
                    } else if ( userInfo.type === "local" || userInfo.type === "admin" ) {
                        bandInfo.localVotes = bandInfo.localVotes + 1;
                        bandInfo.localNames = [...bandInfo.localNames, currentUser.UID];
                    } else if ( userInfo.type === "judge" ) {
                        bandInfo.judgeVotes = bandInfo.judgeVotes + 1;
                        bandInfo.judgeNames = [...bandInfo.judgeNames, currentUser.UID];
                    }

                    transaction.update (userRef, userInfo);
                    transaction.update (bandRef, bandInfo);

                    setCurrentUser ({ 'UID': currentUser.UID, 'userInfo': userInfo })
                    localStorage.setItem('userData', JSON.stringify({ 'UID': currentUser.UID, 'userInfo': userInfo }))
                } else {
                    return false
                }
            })
            return true;
        } catch (err) {
            console.error(err)
            return false;
        }

    }

    async function unVote () {

        const userRef = doc(db, "users", currentUser.UID);

        try {
            await runTransaction (db, async (transaction) => {
                const userSnap = await transaction.get(userRef);
                let userInfo = userSnap.data();

                const bandRef = doc(db, "bands", userInfo.voteId)
                const bandSnap = await transaction.get(bandRef)
                let bandInfo = bandSnap.data()

                userInfo.hasVoted = false;
                userInfo.voteId = null;

                let foreignNamesRef = bandInfo.foreignNames;
                let localNamesRef = bandInfo.localNames;
                let judgeNamesRef = bandInfo.judgeNames;

                if ( userInfo.type === "foreign"  ) {
                    if (bandInfo.foreignVotes > 0) {
                        bandInfo.foreignVotes = bandInfo.foreignVotes - 1;
                        bandInfo.foreignNames = foreignNamesRef.filter((ele) => { return ele !== currentUser.UID;});
                    }
                } else if (userInfo.type === "local" || userInfo.type === "admin") {
                    if (bandInfo.localVotes > 0) {
                        bandInfo.localVotes = bandInfo.localVotes - 1;
                        bandInfo.localNames = localNamesRef.filter((ele) => { return ele !== currentUser.UID;});
                    }
                } else if (userInfo.type === "judge") {
                    if (bandInfo.judgeVotes > 0) {
                        bandInfo.judgeVotes = bandInfo.judgeVotes - 1;
                        bandInfo.judgeNames = judgeNamesRef.filter((ele) => { return ele !== currentUser.UID;});
                    }
                }

                transaction.update (userRef, userInfo);
                transaction.update (bandRef, bandInfo);

                setCurrentUser ({ 'UID': currentUser.UID, 'userInfo': userInfo })
                localStorage.setItem('userData', JSON.stringify({ 'UID': currentUser.UID, 'userInfo': userInfo }))
            })
            return true

        } catch (err) {
            console.error(err)
        }
    }

    async function changeVote (bandId) {
        const newBandRef = doc(db, 'bands', bandId);
        const userRef = doc(db, 'users', currentUser.UID);

        try {
            await runTransaction(db, async (transaction) => {
                const userSnap = await transaction.get(userRef);
                const newBandSnap = await transaction.get(newBandRef);

                let userInfo = userSnap.data();
                let newBandInfo = newBandSnap.data();

                const oldBandRef = doc(db, "bands", userInfo.voteId);
                const oldBandSnap = await transaction.get(oldBandRef);
                let oldBandInfo = oldBandSnap.data();

                let foreignNamesRef = oldBandInfo.foreignNames;
                let localNamesRef = oldBandInfo.localNames;
                let judgeNamesRef = oldBandInfo.judgeNames;

                if ( userInfo.type === "foreign"  ) {
                    if (oldBandInfo.foreignVotes > 0) {
                        oldBandInfo.foreignVotes = oldBandInfo.foreignVotes - 1;
                        oldBandInfo.foreignNames = foreignNamesRef.filter((ele) => { return ele !== currentUser.UID;});
                    }
                } else if (userInfo.type === "local" || userInfo.type === "admin") {
                    if (oldBandInfo.localVotes > 0) {
                        oldBandInfo.localVotes = oldBandInfo.localVotes - 1;
                        oldBandInfo.localNames = localNamesRef.filter((ele) => { return ele !== currentUser.UID;});
                    }
                }else if (userInfo.type === "judge") {
                    if (oldBandInfo.judgeVotes > 0) {
                        oldBandInfo.judgeVotes = oldBandInfo.judgeVotes - 1;
                        oldBandInfo.judgeNames = judgeNamesRef.filter((ele) => { return ele !== currentUser.UID;});
                    }
                }

                userInfo.voteId = bandId;

                if ( userInfo.type === "foreign" ) {
                    newBandInfo.foreignVotes = newBandInfo.foreignVotes + 1;
                    newBandInfo.foreignNames = [...newBandInfo.foreignNames, currentUser.UID];
                } else if ( userInfo.type === "local" || userInfo.type === "admin" ) {
                    newBandInfo.localVotes = newBandInfo.localVotes + 1;
                    newBandInfo.localNames = [...newBandInfo.localNames, currentUser.UID];
                } else if ( userInfo.type === "judge" ) {
                    newBandInfo.judgeVotes = newBandInfo.judgeVotes + 1;
                    newBandInfo.judgeNames = [...newBandInfo.judgeNames, currentUser.UID];
                }

                transaction.update(userRef, userInfo);
                transaction.update(newBandRef, newBandInfo);
                transaction.update(oldBandRef, oldBandInfo);

                setCurrentUser ({ 'UID': currentUser.UID, 'userInfo': userInfo })
                localStorage.setItem('userData', JSON.stringify({ 'UID': currentUser.UID, 'userInfo': userInfo }))
            });

            return true;

        } catch (err) {
            console.error(err);
            return false;
        }

    }


    const value = {
        votingState,
        currentUser,
        login,
        verify,
        createUser,
        logout,
        vote,
        unVote,
        changeVote
    }

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
}

export { useUser, UserProvider }