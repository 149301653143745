import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './index';

const voteRef = doc( db, 'admin', 'I1Co8QKLF1AqLcKeoAav' )

async function toggleVoting () {
    try {
        let voteSnap = await getDoc(voteRef);
        let voteInfo = voteSnap.data();
        voteInfo.voting = !voteInfo.voting;
        await setDoc(voteRef, voteInfo)
        return true
    } catch (err) {
        console.error(err);
        return false;
    }
}

async function getVotingStatus () {
    try {
        let voteSnap = await getDoc(voteRef);
        let voteInfo = voteSnap.data();
        return voteInfo.voting
    } catch (err) {
        console.error(err);
    }
}

export { toggleVoting, getVotingStatus }