import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'


const firebaseConfig = {
  apiKey: "AIzaSyDwBd7i3CUgS8Ja_RR6eqH4bhEgQwX19-k",
  authDomain: "sawtiilak2022.firebaseapp.com",
  projectId: "sawtiilak2022",
  storageBucket: "sawtiilak2022.appspot.com",
  messagingSenderId: "850808471110",
  appId: "1:850808471110:web:e7ee1b87b6e3d1a25bd447",
  measurementId: "G-6M3EXJWET2"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db }