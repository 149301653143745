import { collection, getDocs } from 'firebase/firestore';
import { db } from './index';

function calculateScore (localVotes, foreignVotes, judgeVotes, totalLocalVotes, totalForeignVotes, totalJudgeVotes) {

    // const totalLocalVotes = bands.reduce((acc, cur) => { return (acc + cur.data().localVotes) }, 0);
    // const totalForeignVotes = bands.reduce((acc, cur) => { return (acc + cur.data().foreignVotes) }, 0);
    // const totalJudgeVotes = 4;

    console.log(localVotes, foreignVotes, judgeVotes, totalLocalVotes, totalForeignVotes, totalJudgeVotes)

    const judgesWeight = 0.4;
    let localVoteWeight = 0.45;
    let foreignVoteWeight = 0.15;

    const localVoteImportance  = 1/totalLocalVotes * localVoteWeight;
    const foreignVoteImportance = 1/totalForeignVotes * foreignVoteWeight;

    console.log('localVoteImportance', localVoteImportance)
    console.log('foreignVoteImportance', foreignVoteImportance)

    if (foreignVoteImportance < localVoteImportance) {

        if (totalForeignVotes === 0) {
            localVoteWeight = 0.6;
            foreignVoteWeight = 0;
        }

        let localPercentage = (localVotes/totalLocalVotes)*(localVoteWeight);
        let foreignPercentage = (foreignVotes/totalForeignVotes)*(foreignVoteWeight);
        let judgePercentage = (judgeVotes/totalJudgeVotes)*(judgesWeight);

        if (  isNaN(localPercentage) ) {
            localPercentage = 0;
        }
        if ( isNaN(foreignPercentage) ) {
            foreignPercentage = 0;
        }
        if ( isNaN(judgePercentage) ) {
            judgePercentage = 0;
        }

        return localPercentage + foreignPercentage + judgePercentage;

    } else {
        let localAndForeignPercentage = ((localVotes + foreignVotes) / (totalLocalVotes + totalForeignVotes) )*(localVoteWeight + foreignVoteWeight);
        let judgePercentage =  (judgeVotes/totalJudgeVotes)*(judgesWeight);

        if ( isNaN(localAndForeignPercentage) ) {
            localAndForeignPercentage = 0;
        }
        if ( isNaN(judgePercentage) ) {
            judgePercentage = 0;
        }

        return localAndForeignPercentage + judgePercentage;
    }
  }

async function getBandScores () {
    try {
        const bandsRef = collection(db, 'bands');
        const bandsSnap = await getDocs(bandsRef);
        const bandsInfo = bandsSnap.docs

        let totalLocalVotes = 0;
        let totalForeignVotes = 0;
        let totalJudgeVotes = 0;

        bandsInfo.forEach((band) => {
            let bandData = band.data()
            totalLocalVotes = totalLocalVotes + bandData.localVotes;
            totalForeignVotes = totalForeignVotes + bandData.foreignVotes;
            totalJudgeVotes = totalJudgeVotes + bandData.judgeVotes;
        })

        return bandsInfo.map((band) => {
            let bandData = band.data()
            let score = calculateScore(bandData.localVotes, bandData.foreignVotes, bandData.judgeVotes, totalLocalVotes, totalForeignVotes, totalJudgeVotes)
            return {
                name: bandData.name,
                song: bandData.song,
                score: score,
            }
        })

    } catch (err) {
        console.error(err)
        return []
    }
}

export default getBandScores