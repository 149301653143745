import React from "react";
import { Routes, Route } from "react-router-dom";
import { ROUTES } from "./utils";
import { UserProvider } from "./context";
import { AdminRoutes } from "./components"

function App() {
  return (
        <UserProvider>
          <div id='sign-in-button'></div>
          <Routes>
            {
              ROUTES.map((route, index) => {
                if (route.protected) {
                  return <Route key={`route-protection-${index}`} element={<AdminRoutes />}>
                            <Route key={`route-${index}`} path={route.path} element={route.component} />
                         </Route>
                } else {
                  return <Route key={`route-${index}`} path={route.path} element={route.component} />
                }
              })
            }
          </Routes>
        </UserProvider>
  );
}

export default App;
