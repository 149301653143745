import pic1 from './pic1.jpg';
import pic2 from './pic2.jpg';
import pic3 from './pic3.jpg';
import pic4 from './pic4.jpg';
import pic5 from './pic5.jpg';
import pic6 from './pic6.jpg';
import pic7 from './pic7.jpg';
import pic8 from './pic8.jpg';
import pic9 from './pic9.jpg';
import pic10 from './pic10.jpg';
import pic11 from './pic11.jpg';
import pic12 from './pic12.jpg';
import pic13 from './pic13.jpg';
import pic14 from './pic14.jpg';
import pic15 from './pic15.jpg';
import pic16 from './pic16.jpg';
import pic17 from './pic17.jpg';
import pic18 from './pic18.jpg';
import pic19 from './pic19.jpg';
import pic20 from './pic20.jpg';
import pic21 from './pic21.jpg';
import pic22 from './pic22.jpg';
import pic23 from './pic23.jpg';
import pic24 from './pic24.jpg';
import pic25 from './pic25.jpg';
import pic26 from './pic26.jpg';
import pic27 from './pic27.jpg';
import pic28 from './pic28.jpg';
import pic29 from './pic29.jpg';
import pic30 from './pic30.jpg';
import pic31 from './pic31.jpg';
import pic32 from './pic32.jpg';
import pic33 from './pic33.jpg';
import pic34 from './pic34.jpg';
import pic35 from './pic35.jpg';
import pic36 from './pic36.jpg';
import pic37 from './pic37.jpg';
import pic38 from './pic38.jpg';

const pictures = [pic1,pic2,pic3,pic4,pic5,pic6,pic7,pic8,pic9,pic10,pic11,pic12,pic13,pic14,pic15,pic16,pic17,pic18,pic19,pic20,pic21,pic22,pic23,pic24,pic25,pic26,pic27,pic28,pic29,pic30,pic31,pic32,pic33,pic34,pic35,pic36,pic37,pic38]

export default pictures;