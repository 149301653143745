import ChurchOfGodDora from "../../assets/sawtiIlak2023/bandPhotos/COG DORA BAND.jpg";
import ChristEvangelicalBabtist from "../../assets/sawtiIlak2023/bandPhotos/CEBC.jpg";
import ChurchOfGodLifeCenter from "../../assets/sawtiIlak2023/bandPhotos/COG - Life Center.jpg"

const BANDS2023 = [
    {
        order: 3,
        id: 2023_1,
        bandName: "Church Of God (Dora)",
        songName: "آتي لَكَ",
        bandImage: ChurchOfGodDora,
        lyrics:
            <>
                آتي لَكَ وأنا خَاطي الحَالْ
                <br />
                آتي لَكَ من قَلبِ الظِّلالْ
                <br />
                مُتعَبٌ وثَقيلُ الأَحمالْ
                <br />
                آتي لَكَ مثلَ الإبن الضَّالْ
                <br />
                <br />
                شُكرًا لَكَ قد سَامحتَني
                <br />
                شُكرًا لَكَ قَد خَلَّصْتَني
                <br />
                وَرَفَعْتَ أَحمَالي عَنّي
                <br />
                وَقُلتَ لي أنتَ إبني
            </>,
        author: "فاهان كريكوريان",
        composer: "جاك خاتشادوريان",
        year: 2023
    },
    {
        order: 4,
        id: 2023_2,
        bandName: "Church Of God (Dora)",
        songName: "إحمَدوا الرَّبَّ",
        bandImage: ChurchOfGodDora,
        lyrics:
            <>
                إِحمَدوا الرَّبَّ على رَحمَتِهِ
                <br />
                قد صَنَعَ خَلاصًا لِشَعبِهِ
                <br />
                أَنقَذَنا مِنْ يَدِ العَدوّ،
                <br />
                وَهَبَنَا الحَياةَ بِمَوتِهِ
                <br />
                <br />
                أَرسَلَ كلمَتَه فَشَفانا
                <br />
                ومَعْ أَشرَافَ شَعبِهِ أَجلَسَنا
                <br />
                جَعَلَنا مُلوكًا وكَهَنَة
                <br />
                ومِنَ العُبودِيَّةِ حَرَّرَنا
                <br />

                <br />
                يا شُعوبَ الأرضِ لَهُ اِهتِفوا
                <br />
                بارِكوهُ وعَظِّمُوا اِسمَهُ
                <br />
                سَبِّحُوهُ واعلِنوا مَجْدَهُ
                <br />
                قَدْ غَلَبَ الحَمَلْ وأعطانا نَصْرَهُ
                <br />

                <br />
                قريبًا سَيَأتي على السَّحابْ
                <br />
                وسَتنظُرُهُ كلُّ العُيونْ
                <br />
                سَتَسجُدُ لَهُ كُلُّ الرُّكَبْ
                <br />
                حَتّى الّذين طَعَنوه
                <br />

                <br />
                سيأخُذُنا لِنَكونَ مَعهُ
                <br />
                معًا سَنَكونُ للأَبَدْ
                <br />
                سَنَعيشُ في ملئِ حُضورِهِ
                <br />
                لا موتَ، لا صُراخَ سيكونُ فيما بَعدْ
                <br />

                <br />
                يا شُعوبَ الأرضِ لَهُ اِهتِفوا
                <br />
                بارِكوهُ وعَظِّمُوا اِسمَهُ
                <br />
                سَبِّحُوهُ واعلِنوا مَجْدَهُ
                <br />
                قَدْ غَلَبَ الحَمَلْ وأعطانا نَصْرَهُ
                <br />
            </>,
        author: "رانيا خوري",
        composer: "رانيا خوري",
        year: 2023
    },
    {
        order: 1,
        id: 2023_3,
        bandName: "Christ Evangelical Baptist Church (Fanar)",
        songName: "بَعدَ ذَرفِ الدُّموعِ",
        bandImage: ChristEvangelicalBabtist,
        lyrics:
            <>
                بَعدَ ذَرفِ الدُّموعِ والبُكاءِ والأَنينْ
                <br />
                وضَجَّةِ الجُّموعِ وغَضَبِ الحاقِدينْ
                <br />
                بَعدَ الدَّفنِ الوَضيعِ والمُنى بالخَيبَةِ
                <br />
                أشرَقَتْ شَمسُ البِرِّ والشِّفا
                <br />

                <br />
                قامَ المَسيحْ بِالمَجْدِ هَلِّلويا)
                <br />
                كَشَمسٍ سَطَعَت أَنوارُ القِيامَةِ
                <br />
                قَدْ غَلَبَ المَوتَ إذ هوَ رَبُّ الحَياةِ
                <br />
                واِنتَفَضَ الحَقُّ نوراً وَسطَ الظُّلمَةِ)2
                <br />

                <br />
                بَعدَ الصَّلبِ الرَّهيبِ ومَساميرِ الحَديدْ
                <br />
                وآلامِ الطَّريقِ بِعَزمِ الحُبِّ الشَّديدْ
                <br />
                بَعدَ نُكرانِ الكُّلِّ وَمُرِّ الخِيانَةِ
                <br />
                ها قَلبُهُ يَنبُضُ مِن جَديدْ
                <br />

                <br />
                قامَ المَسيحْ بِالمَجْدِ هَلِّلويا)
                <br />
                كَشَمسٍ سَطَعَت أَنوارُ القِيامَةِ
                <br />
                قَدْ غَلَبَ المَوتَ إذ هوَ رَبُّ الحَياةِ
                <br />
                واِنتَفَضَ الحَقُّ نوراً وَسطَ الظُّلمَةِ)2
                <br />

                <br />
                بَعدَ حِقدِ السِّياطِ وسَيَلانِ الدِّماءْ
                <br />
                وَظُلمِ حُكْمِ النَّاسِ على كَبِشِ الفِداءْ
                <br />
                بعْدَ التَآمُرِ لِقَتْلِ المَحَبَّةِ
                <br />
                قامَ فهَزَّ الأَرضَ والسَّما
                <br />

                <br />
                قامَ المَسيحْ بِالمَجْدِ هَلِّلويا)
                <br />
                كَشَمسٍ سَطَعَت أَنوارُ القِيامَةِ
                <br />
                قَدْ غَلَبَ المَوتَ إذ هوَ رَبُّ الحَياةِ
                <br />
                واِنتَفَضَ الحَقُّ نوراً وَسطَ الظُّلمَةِ)2
            </>,
        author: "الأخ طوني سمعان",
        composer: "الأخ طوني سمعان",
        year: 2023
    },
    {
        order: 6,
        id: 2023_4,
        bandName: "Church Of God (Life Center)",
        songName: "شَوقُ قَلبي",
        bandImage: ChurchOfGodLifeCenter,
        lyrics:
            <>
                شَوقُ قَلبي يا رَبّي
                <br />
                أنْ أَعبُدَ إِسمَكَ
                <br />
                روحي نَفسي وجَسَدي
                <br />
                كُلّي لَكَ
                <br />

                <br />
                أَنتَ بَلسَم لِجُرحي
                <br />
                نُصرَتي بِكَ
                <br />
                باِسمِكَ أَقتَحِمُ جَيشًا
                <br />
                لأَنَّكَ فِيَّ
                <br />

                <br />
                أُحِبُّكَ، أَعبُدُكَ، أُحتاجُكَ، ما أروعَكَ
            </>,
        author: "شربل حدشيتي",
        composer: "شربل حدشيتي",
        year: 2023
    },
    {
        order: 2,
        id: 2023_5,
        bandName: "Christ Evangelical Baptist Church (Fanar)",
        songName: "في قصَّةٍ يُخشَعْ لها",
        bandImage: ChristEvangelicalBabtist,
        lyrics:
            <>
                في قصَّةٍ يُخشَعْ لها حُبُّ يَسوعْ فاضَ بنا
                <br />
                كانَ القَرارُ خلاصَنا، مِنَ الخَطيّةِ تَحريرَنا
                <br />
                مِنْ أَجْلِ هذا جاءَ يَسوعْ، أَخلَى الأَمجادَ كالعَبدِ صارْ
                <br />
                عاشَ إنسانًا وَهوَ إلهًا حَتّى يُنقِذنا عِقابَ النّارْ
                <br />

                <br />
                على الصَّليبِ تَمَّ الخَلاصْ على الصَّليبِ ولّى القِصاصْ
                <br />
                على الصَّليبِ بِكُلّْ إخلاصٍ صارَ يسوعُ لَنا المَناصْ
                <br />
                على الصَّليبِ فاضَ الحَنانْ على الصَّليبِ شَقَّ الزَّمانْ
                <br />
                على الصَّليبِ هّزَّ الأكوانَ حرَّرَ الخاطي (وأَعطاهْ غُفرانْ)2
                <br />

                <br />
                كانَ بَديلَ نُفوسِنا حَمَلَ عَنَّا خَطايانا
                <br />
                صارَ مَلعونًا مِنْ أجلِنا لِيَمحي الصَكَّ الّذي لَنا
                <br />
                على الصَّليبِ لَمْ يُعطَ ماءْ، على الصَّليبِ تَمَّ الفِداءْ
                <br />
                حَتّى يُعطينا كُلَّ الهَناءِ عُلِّقَ بَيْنَ أرضٍ وسَماءْ
                <br />

                <br />
                على الصَّليبِ تَمَّ الخَلاصْ على الصَّليبِ ولّى القِصاصْ
                <br />
                على الصَّليبِ بِكُلّْ إخلاصٍ صارَ يسوعُ لَنا المَناصْ
                <br />
                على الصَّليبِ فاضَ الحَنانْ على الصَّليبِ شَقَّ الزَّمانْ
                <br />
                على الصَّليبِ هّزَّ الأكوانَ حرَّرَ الخاطي (وأَعطاهْ غُفرانْ)2
                <br />

                <br />

                <br />
                مِنَ الأَشرارِ قاسَ الأَهوالْ، جَلْداً وَحْشيًّا فاقَ الخَيالْ
                <br />
                على طَريقِ الجُلجُثَه سارَ، كَشاةٍ سيقَ لَمْ يَفتَحْ فاهْ
                <br />
                ثُقِبَتْ يَداهُ وَرِجلاهْ بالمَساميرِ وهوَ الإِلَهْ
                <br />
                صَرَخَ أُكمِلْ، أسلَمَ الرُّوحَ مُطَهِّراً الخاطي بِدِماهْ
            </>,
        author: "الأخ طوني بيضون",
        composer: "الأخ طوني بيضون",
        year: 2023
    },
    {
        order: 5,
        id: 2023_6,
        bandName: "Church Of God (Life Center)",
        songName: "مين غَيرَكْ",
        bandImage: ChurchOfGodLifeCenter,
        lyrics:
            <>
                مين غَيرَكْ بِطَمِّن قَلبي
                <br />
                إنتَ يا فادي الإِنسان
                <br />
                مين غَيرَكْ عَم يِهدي دَرْبي
                <br />
                وبِيعَزّي قَلبي الزَّعلان
                <br />

                <br />
                كلّ أَمَلي ورَجائي فيكْ
                <br />
                واثِقْ إنّي غالي عَلَيكْ
                <br />
                إنتَ يَلّي لَدَربي شريكْ
                <br />
                مَعَك بتِختِفي الأحزَانْ
                <br />

                <br />
                مين غَيرَكْ بِطَمِّن قَلبي
                <br />
                إنتَ يا فادي الإِنسان
                <br />
                مين غَيرَكْ عَم يِهدي دَرْبي
                <br />
                وبِيعَزّي قَلبي الزَّعلان
                <br />

                <br />
                بتِرعاني دايمًا بعِنَيكْ
                <br />
                بتُغمُرني بلَمسِة إدَيكْ
                <br />
                كلّ إِتِّكالي رَبّي عَلَيكْ
                <br />
                يا مفَرِّح قَلبي التَّعبان
                <br />

                <br />
                مين غَيرَكْ بِطَمِّن قَلبي
                <br />
                إنتَ يا فادي الإِنسان
                <br />
                مين غَيرَكْ عَم يِهدي دَرْبي
                <br />
                وبِيعَزّي قَلبي الزَّعلان
            </>,
        author: "طليع خليل",
        composer: "طليع خليل",
        year: 2023
    }
]

export { BANDS2023 }
