import React from "react";
import "./styles.css";

import MECLogo from "../../assets/MEC Logo without text.svg";
import SawtiLogo from "../../assets/logoWithText.svg";
import Facebook from "../../assets/facebook.svg";
import Instagram from "../../assets/instagram.svg";
import Youtube from "../../assets/youtube.svg";
import Whatsapp from "../../assets/whatsapp.svg";
import Mail from "../../assets/mail.svg";

function Footer() {


    return (
        <>
            <div id="footer">
                <div id="inner-footer">

                    <div id="footer-logos">
                        <img className="mec-logo-img" alt="MEC Logo" src={MECLogo} onClick={() => { window.location.assign("https://middleeastcenter.org/") }}/>
                        <img className="sawti-logo-img" alt="MEC Logo" src={SawtiLogo} />
                    </div>

                    <div className="footer-divider" />

                    <div id="footer-social-media">
                        <p>Follow us on:</p>
                        <div id="footer-social-media-icons">
                            <img className="social-media" alt="Facebook" src={Facebook} onClick={() => { window.location.assign("https://www.facebook.com/meceventsmiddleeastcenter") }} />
                            <img className="social-media" alt="Instagram" src={Instagram} onClick={() => { window.location.assign("https://www.instagram.com/mec.events.middleeastcenter/") }} />
                            <img className="social-media" alt="Youtube" src={Youtube} onClick={() => { window.location.assign("https://www.youtube.com/@mecworship7787") }} />
                        </div>
                    </div>

                    <div className="footer-divider" />

                    <div id="footer-contact-us">
                        <p style={{ textAlign: "center" }}>Contact us at:</p>
                        <div id="contact-us-info">
                            <div style={{ display: "flex", cursor: "pointer" }} onClick={() => { window.location.replace("mailto::worship@middleeastcenter.org") }} >
                                <img style={{ marginRight: "10px" }} className="social-media" alt="Mail" src={Mail} />
                                <p className="contact-us-info-details">worship@middleeastcenter.org</p>
                            </div>
                            <div style={{ display: "flex", cursor: "pointer" }} onClick={() => { window.location.replace("tel:+96181947903") }} >
                                <img style={{ marginRight: "10px" }} className="social-media" alt="Whatsapp" src={Whatsapp} />
                                <p className="contact-us-info-details"> +961 81 947 903</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Footer;
