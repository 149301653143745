import React from 'react';

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../assets/logo.svg";
import Nav from "react-bootstrap/Nav";

import { useNavigate } from 'react-router-dom';
import './styles.css';

function TopNavbar() {

    let navigate = useNavigate();


    return (
        <div id='top'>
            <Navbar collapseOnSelect expand="md" bg="transparent" variant="dark">
                <Container fluid className="px-lg-5">

                    <Navbar.Brand className="top-nav-logo" onClick={() => { navigate('/') }}>
                        <img
                            alt="Sawti Ilak Microphone Logo"
                            src={Logo}
                            width="14.467"
                            height="20"
                            className="d-inline-block nav-logo"
                        />
                        {' '}
                        <p className="d-inline-block fs-4">Sawti Ilak</p>
                    </Navbar.Brand>


                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse>
                    <Nav
                        className='nav-adjust flex-grow-1 justify-content-center navbar-adjustment'
                    >
                        <Nav.Item>
                            <Nav.Link href="/">Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/archive">Previous Events</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Navbar.Collapse>

                    {/*<Nav className="nav-adjust me-auto justify-content-center">
                        <Nav.Item>
                            <Nav.Link>Home</Nav.Link>
                        </Nav.Item>
                        {/*showBandsAndJudges &&
                                <>
                                    <Nav.Link onClick={() => { navigate('/#top') }}>Home</Nav.Link>
                                    <Nav.Link onClick={() => { navigate('/archive') }}>Archive</Nav.Link>
                                    <Nav.Link href="#bands">Bands</Nav.Link>
                                    <Nav.Link href="#judges">Judges</Nav.Link>
                                </>
                            */}

                    {/*showCompetition &&
                                <>
                                    <Nav.Link onClick={() => { navigate('/#top') }}>Home</Nav.Link>
                                    <Nav.Link onClick={() => { navigate('/archive') }}>Archive</Nav.Link>
                                    <Nav.Link onClick={() => { navigate(`/sawti-ilak-${year}`) }}>Back</Nav.Link>
                                </>
                            */}
                    {/*
                                currentUser && currentUser.userInfo.type === 'admin' &&
                                <Nav.Link onClick={() => { navigate('/admin#top') }}>Admin Controls</Nav.Link>
                            */}
                    {/*
                                location.pathname.match("/song") &&
                                <>
                                    <Nav.Link onClick={() => { navigate('/#top') }}>Home</Nav.Link>
                                    <Nav.Link onClick={() => { navigate('/archive') }}>Archive</Nav.Link>
                                    <Nav.Link onClick={() => { navigate(`/sawti-ilak-${songCompetition}`) }}>Back</Nav.Link>
                                </>
                            */}
                    {/*location.pathname === "/" &&
                                <Nav.Link id="sawti-ilak-nav-link" onClick={() => { navigate('/archive') }}>Archive</Nav.Link>
                            */}
                    {/*
                            { !currentUser ?
                            <Nav.Link onClick={() => {navigate('/login#top')}}>Sign Up / Login</Nav.Link>
                            :
                            <Nav.Link onClick={() => {userLogOut()}}>Logout</Nav.Link>
                            }
                        
                    </Nav>*/}
                </Container>
            </Navbar>
        </div>
    )
}

export default TopNavbar;
