import React from "react";
import "./styles.css";

import { TopNavbar, Footer } from "../../components";

function HomeLayout ({ children }) {

    return (
        <>
            <div className="d-flex flex-column">
                <div id="fullHeight">
                <div id="hero-section">
                    <TopNavbar />
                    {children[0] ? children[0] : children}
                </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default HomeLayout;
