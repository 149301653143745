import React, {useState, useEffect} from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { MainLayout } from '../../layouts';
import { useUser } from '../../context';

import { getBandScores, toggleVoting, getVotingStatus, addBand } from '../../api';

import './styles.css'

function Admin () {
    const { currentUser } = useUser();
    const [ bandScores, setBandScores ] = useState([]);
    const [ votingStatus, setVotingStatus ] = useState('Loading');

    const [bandId, setBandId] = useState('');
    const [bandName, setBandName] = useState('');
    const [bandSong, setBandSong] = useState('');

    const userInfo = currentUser.userInfo;

    useEffect (() => {
        async function getAndSetVotingStatus () {
            let status = await getVotingStatus();
            setVotingStatus(status)
        }
        getAndSetVotingStatus()

    }, [])

    async function toggleVotingAndSet () {
            let success = await toggleVoting();
            if ( success ) {
                setVotingStatus(!votingStatus)
            }
    }


    async function showScores () {
        const scores = await getBandScores();
        scores.sort( (a, b) => {
            if ( a.score > b.score ) {
                return -1;
            }
            if ( a.score < b.score ) {
                return 1;
            }
            return 0;
        } )
        setBandScores(scores)
    }

    async function createBand () {
        const success = await addBand(bandId, bandName, bandSong);
        if (success) {
            setBandId('')
            setBandName('')
            setBandSong('')
            console.log('added')
        }
    }

    return (
        <MainLayout>
            <Container>
                <Row>
                    <Col xs={12} className='d-flex align-items-center justify-content-center'>
                        <h1>Welcome {userInfo.firstName}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='d-flex align-items-center justify-content-center mt-3'>
                        <Button variant="light" onClick={() => {toggleVotingAndSet()}}>{votingStatus === 'Loading' ? 'Loading' : votingStatus ? 'Stop Voting' : 'Start Voting' }</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='d-flex align-items-center justify-content-center mt-3'>
                        <Button variant="light" onClick={() => {showScores()}}>Get Scores</Button>
                    </Col>
                </Row>
                { bandScores.map((band, index) => {
                    return (
                    <Row key={`band-${index}`}>
                        <Col className='d-flex align-items-center justify-content-center mt-3'><p>{(band.score.toPrecision(4)*100).toPrecision(3)}%</p></Col>
                        <Col className='d-flex align-items-center justify-content-center mt-3'><p>{band.name}</p></Col>
                        <Col className='d-flex align-items-center justify-content-center mt-3'><p>{band.song}</p></Col>
                    </Row>
                    )
                })
                }
                {/* <p>id:</p>
                <input value={bandId} onChange={(e) => {setBandId(e.target.value)}} />
                <p>name:</p>
                <input value={bandName} onChange={(e) => {setBandName(e.target.value)}} />
                <p>song:</p>
                <input value={bandSong} onChange={(e) => {setBandSong(e.target.value)}} />
                <Button onClick={() => createBand()}>
                    Add Band
                </Button> */}
            </Container>
        </MainLayout>
    )
}

export default Admin;