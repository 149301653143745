import React, { useState, useEffect } from "react";
import { MainLayout } from "../../layouts";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button"

import { useUser } from '../../context'
import { useNavigate, useLocation } from "react-router-dom";

import './styles.css';

function Signup ( ) {

    const { currentUser, createUser } = useUser(null);
    const navigate = useNavigate();
    let location = useLocation();

    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phoneNumber, setPhoneNumber] = useState(currentUser.userInfo.phoneNumber);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);

    useEffect(() => {
             if ( location.hash &&  location.hash === '#top') {
                window.scrollTo(0,0)
            }
        } , [location.hash]);

    async function registerUser () {
        if ( !firstName || !lastName ) {
            setError(true);
            return false;
        }
        setLoading(true);
        let success = await createUser({ 'firstName': firstName, 'lastName': lastName, 'email': email })
        if (success) {
            console.log('User Created');
            navigate('/#top');
        } else {
            console.log('There has been an error')
            setLoading(false)
        }
    }

    return (
        <MainLayout>
            <Container className="align-content-center">
                <Row>
                    <Col className="text-center text">
                        <h1>Registration</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center text">
                        <h4>Please enter the following Information so you can vote:</h4>
                    </Col>
                </Row>
                <Row className="pt-4">
                    <Col className="text-center">
                        <h5>First Name:</h5>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center pt-2">
                        <input className="first-last-name" value={firstName} onChange={(e) => {setFirstName(e.target.value)}} />
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col className="text-center">
                        <h5>Last Name:</h5>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center pt-2">
                        <input className="first-last-name" value={lastName} onChange={(e) => {setLastName(e.target.value)}} />
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col className="text-center">
                        <h5>Email:</h5>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center pt-2">
                        <input className="first-last-name" value={email} onChange={(e) => {setEmail(e.target.value)}} />
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col className="text-center" >
                        <h5>Phone Number:</h5>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center pt-2">
                        <input className="first-last-name" value={phoneNumber} disabled={true} />
                    </Col>
                </Row>
                { error &&
                <Row className="pt-4S">
                    <Col className="text-center" >
                        <h5>Please Complete the Information</h5>
                    </Col>
                </Row>
                }
                <Row>
                    <Col className="d-flex justify-content-center pt-4">
                        <Button variant="light" onClick={() => {registerUser()}} disabled={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Container>
        </MainLayout>
    )
}

export default Signup;