import './styles.css';
import { MainLayout } from "../../layouts";

import Logo from "../../assets/logo.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

function Archive() {

    const navigate = useNavigate();

    return (
        <MainLayout>
            <div className="py-2">
                <div className="bands-backdrop">
                    <Container className='pb-3'>
                        <Row>
                            <Col xs={9} className="d-flex text-center past-event-title">
                                <h1 className="text-center">
                                    Past Events
                                </h1>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center pt-2 mb-5">
                            <div className='divider'></div>
                        </Row>
                        {/*   <Row className='event-row my-2 py-2 mx--1' onClick={() => {navigate("/sawti-ilak-2021")}}>
                            <Col xs={12} className='d-flex align-items-center mb-2'>
                                <h5 style={{ marginBottom: 0 }}>
                                    <li>Sawti Ilak 2021</li>
                                </h5>
                            </Col>
                        </Row>
                        <Row className='event-row my-2 py-2 mx--1' onClick={() => {navigate("/sawti-ilak-2022")}}>
                            <Col xs={12} className='d-flex align-items-center mb-2'>
                                <h5 style={{ marginBottom: 0 }}>
                                    <li>Sawti Ilak 2022</li>
                                </h5>
                            </Col>
                        </Row>
                    */}
                        <Row className="d-flex align-items-center mx-5">

                            <Col xs={12} sm={6} md={3} lg={2} minwidth="130px" className="d-flex align-items-center justify-content-center mb-5">
                                <Button className='pastEventButton' onClick={() => { navigate("/sawti-ilak-2021") }}>
                                    <img
                                        src={Logo}
                                        alt={"Sawti Ilak logo"}
                                        width="30%"
                                        className='mb-1'
                                    />
                                    <h3>2021</h3>
                                </Button>
                            </Col>

                            <Col xs={12} sm={6} md={3} lg={2} minwidth="130px" className="d-flex align-items-center justify-content-center mb-5">
                                <Button className='pastEventButton' onClick={() => { navigate("/sawti-ilak-2022") }}>
                                    <img
                                        src={Logo}
                                        alt={"Sawti Ilak logo"}
                                        width="30%"
                                        className='mb-1'
                                    />
                                    <h3>2022</h3>
                                </Button>
                            </Col>

                            <Col xs={12} sm={6} md={3} lg={2} minwidth="130px" className="d-flex align-items-center justify-content-center mb-5">
                                <Button className='pastEventButton' onClick={() => { navigate("/sawti-ilak-2023") }}>
                                    <img
                                        src={Logo}
                                        alt={"Sawti Ilak logo"}
                                        width="30%"
                                        className='mb-1'
                                    />
                                    <h3>2023</h3>
                                </Button>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>
        </MainLayout>
    )
}

export default Archive;
