import React from 'react';
import { MainLayout } from '../../layouts';
import pictures2021 from '../../assets/sawtiIlak2021/pictures';
import pictures2022 from '../../assets/sawtiIlak2022/pictures';
import pictures2023 from '../../assets/sawtiIlak2023/pictures';

import './styles.css'
import { useParams } from 'react-router-dom';

function Pictures() {
    const { year } = useParams();
    console.log(year);
    let pictures = [];
    if (year === "2021") pictures = pictures2021;
    else if (year === "2022") pictures = pictures2022;
    else if (year === "2023") pictures = pictures2023;
    const numberOfPictures = pictures.length;
    const splitPictures = [];

    let numberOfRows = Math.ceil(numberOfPictures / 4.0);
    for (let i = 0; i < numberOfRows; i++) splitPictures[i] = [];

    for (let i = 0; i < numberOfRows; i++) {
        for (let j = 0; (j < 4 && i * j + j < numberOfPictures); j++) {
            splitPictures[i][j] = (pictures[(i * 4) + j]);
        }
    }

    return (
        <MainLayout>
            <div className="my-row">
                {
                    splitPictures.map((row) => {
                        return (
                            <div className="my-column">
                                {
                                    row.map((picture) => {
                                        return (
                                            <>
                                                <img
                                                    alt={'Sawti Ilak 2021 Pictures'}
                                                    src={picture}
                                                />
                                                <br />
                                            </>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </MainLayout>
    )
}

export default Pictures;
