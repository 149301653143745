import React, { useEffect, useState} from 'react';

import { useParams, useLocation, useNavigate } from 'react-router-dom';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { MainLayout } from '../../layouts';

import { VideoPlayer } from '../../components';
import { BANDS2021, BANDS2022, BANDS2023 } from '../../utils';

import './styles.css';

function Song () {

    let bands = [ ...BANDS2021, ...BANDS2022, ...BANDS2023 ]

    let { id } = useParams();
    let location = useLocation();
    let navigate = useNavigate();

    let currentSong = bands.find((band) => band.id.toString() === id.toString())

    const [activeTab, setActiveTab] = useState(currentSong.position && currentSong.linkToMp4 ? 'media' : 'lyrics');

    useEffect(() => {
        if (location.hash) {
            if (location.hash === '#lyrics') {
                setActiveTab('lyrics')
            }
            else if (location.hash === '#media') {
                setActiveTab('media')
            } else if (location.hash === '#top') {
                window.scrollTo(0,0)
            }
        }
    }
    , [location.hash]);

    return (
        <MainLayout>
            <div className='pb-2 pt-3 mb-1'>
                <div className='song-backdrop'>
                    <Container className='pb-4'>
                        <Row>
                            <Col sm={6} md={4} className="d-flex justify-content-md-end justify-content-center">
                                <img
                                    className="band-image-song-page"
                                    src={currentSong.bandImage}
                                    alt={currentSong.bandName}
                                />
                            </Col>
                            <Col sm={0} md={1}></Col>
                            <Col sm={6} className='d-flex justify-content-md-start text-center justify-content-center  align-items-center'>
                                <div >
                                    <h2 className="band-main-info h2 mt-2">{ currentSong.songName }</h2>
                                    <h2 className="band-main-info h2">{ currentSong.bandName }</h2>
                                    <h5 className="band-main-info h6">كلمات { currentSong.author }</h5>
                                    <h5 className="band-main-info h6">ألحان { currentSong.composer } </h5>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='song-backdrop mt-0 pt-0'>
                    <Tabs defaultActiveKey={(currentSong.position !== '' && currentSong.linkToMp4 !== '')? "song" : "lyrics"} activeKey={activeTab} className="mb-3 tabs" fill onSelect={(e) => {navigate(`${location.pathname}#${e}`)}}>
                    { currentSong.position && currentSong.linkToMp4 &&
                        <Tab eventKey="media" title="Media" tabClassName='tab h5 left-tab'>
                            <Container>
                                {/* <Row>
                                    <Col sm={12} className='d-flex justify-content-center mt-3'>
                                        <div>
                                            <p className="band-main-info h5 mt-2 text-center">{ currentSong.songName }</p>
                                            <p className="band-main-info fst-italic h6 text-center">{ currentSong.bandName }</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center mb-4">
                                        <MusicPlayer songSource='https://www.bensound.com/bensound-music/bensound-memories.mp3' />
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col className="d-flex justify-content-center mb-4">
                                        <VideoPlayer embedId={currentSong.linkToMp4} />
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                    }
                    { currentSong.position && currentSong.linkToMp4 ?
                        <Tab eventKey="lyrics" title="Lyrics" tabClassName='tab h5 right-tab'>
                            <Container>
                                <Row>
                                    <Col className="d-flex flex-column justify-content-center ">
                                        <h5 className='text-center pb-4'>
                                            { currentSong.lyrics }
                                        </h5>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        :
                        <Tab eventKey="lyrics" title="Lyrics" tabClassName='tab h5 only-tab'>
                            <Container>
                                <Row>
                                    <Col className="d-flex flex-column justify-content-center ">
                                        <h5 className='text-center pb-4'>
                                            { currentSong.lyrics }
                                        </h5>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                    }
                    </Tabs>
                </div>
            </div>
        </MainLayout>
    )
}

export default Song;
