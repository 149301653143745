import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './styles.css';

function JudgeCard ({ judgeName, judgePicture, judgeDescription }) {
    return (
        <Row className='my-2 py-2 mx-1'>
            <Col md={1} className='d-flex align-items-center justify-content-center'>
            </Col>
            <Col md={1} className='d-flex align-items-center justify-content-center'>
                <img
                    src={judgePicture}
                    alt={`${judgeName}`}
                    className="judge-image"
                />
            </Col>
            <Col md={3} className='d-flex align-items-center justify-content-center'>
                    <p className="judge-name">{judgeName}</p>
            </Col>
            <Col md={7} className='d-flex align-items-center justify-content-center text-center pe-md-5'>
                <p>{judgeDescription}</p>
            </Col>
        </Row>
    )
}

export default JudgeCard;