import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";

import { ReactComponent as MusicIcon } from '../../assets/playIcon.svg'
import { ReactComponent as LyricsIcon } from '../../assets/lyricsIcon.svg'
import { ReactComponent as VoteIcon } from '../../assets/voteIcon.svg'
import { ReactComponent as VotedIcon } from '../../assets/votedIcon.svg'

import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context'

import './styles.css';

function BandCard ({ linkToMp4, bandName, bandImage, songName, position, composer, author, id, year }) {

    const currentYear = 2023;
    const { votingState, currentUser, vote, unVote, changeVote } = useUser();

    const canVoteFor = currentYear === year && currentUser && votingState;
    let hasVotedForMe = false;

    if ( canVoteFor ) {
        hasVotedForMe = currentUser.userInfo.voteId === id
    }

    const navigate = useNavigate();

    const navigateToSongPlay = () => {
        navigate(`/song/${id}#media`)
    }

    const navigateToLyrics = () => {
        navigate(`/song/${id}#lyrics`)
    }

    async function voteFor (bandId) {
        if ( currentUser.userInfo.firstName ) {
            const success = await vote(bandId)
            success && console.log("Voted for", bandId)
        } else {
            navigate('/signup#top')
        }
    }

    async function removeVoteFor () {
        const success = await unVote()
        success && console.log("UnVote Successful")
    }

    async function changeVoteFor (bandId) {
        const success = await changeVote(bandId)
        success && console.log("Vote Changed to", bandId)
    }

    return (
        <>
        <Row className='band-row my-2 py-2 mx--1'>
            <Col xs={12} md={ currentYear === year ? 1 : 2} className='d-flex align-items-center justify-content-center pb-2' onClick={() => {navigate(`/song/${id}#top`)}}>
                { hasVotedForMe ?
                    <VotedIcon className='icon voted-icon'/>
                    :
                    <p className="winners-text">{position}</p>
                }
            </Col>

            <Col xs={4} md={1} className='d-flex align-items-center justify-content-center' onClick={() => {navigate(`/song/${id}#top`)}}>
                <img
                    src={bandImage}
                    alt={`${bandName}`}
                    className="band-image-2"
                />
            </Col>

            <Col xs={8} md={ currentYear === year ? 3 : 5} className='d-flex align-items-center p-xs-0' onClick={() => {navigate(`/song/${id}#top`)}}>
                <div>
                    <p>{songName}</p>
                    <p>{bandName}</p>
                </div>
            </Col>

            { currentYear === year &&
            <Col md={2} className='d-flex align-items-center justify-content-center md-hidden' onClick={() => {navigate(`/song/${id}#top`)}}>
                <p className='rtl'> {`ألحان: ${composer} `}</p>
            </Col>
            }

            { currentYear === year &&
            <Col md={2} className='d-flex align-items-center justify-content-center md-hidden' onClick={() => {navigate(`/song/${id}#top`)}}>
                <p className='rtl'>{`كلمات:  ${author} `}</p>
            </Col>
            }

            { linkToMp4 && position &&
            <Col xs={6} md={1} className="d-flex align-items-center justify-content-center">
                <Button variant="link" className="d-inline-block icon-button" onClick={() => {navigateToSongPlay()}}>
                    <MusicIcon className='icon music-icon' />
                    <p className='text-center icon-text'>Play</p>
                </Button>
            </Col>
            }

            { canVoteFor &&
            <>
            { !currentUser.userInfo.hasVoted ?
            <Col xs={6} md={1} className="d-flex align-items-center justify-content-center voteButton">
                <Button variant="link" className="d-inline-block icon-button" onClick={() => {voteFor(id)}}>
                    <VoteIcon className='icon lyrics-icon' />
                    <p className='text-center icon-text'>Vote</p>
                </Button>
            </Col>
            :
            <>
            { currentUser.userInfo.voteId === id ?
            <Col xs={6} md={1} className="d-flex align-items-center justify-content-center voteButton">
                <Button variant="link" className="d-inline-block icon-button" onClick={() => {removeVoteFor()}}>
                    <VoteIcon className='icon lyrics-icon' />
                    <p className='text-center icon-text'>Undo</p>
                </Button>
            </Col>
            :
            <Col xs={6} md={1} className="d-flex align-items-center justify-content-center voteButton">
                <Button variant="link" className="d-inline-block icon-button" onClick={() => {changeVoteFor(id)}}>
                    <VoteIcon className='icon lyrics-icon' />
                    <p className='text-center icon-text'>Change</p>
                </Button>
            </Col>
            }
            </>
            }
            </>
            }

            <Col xs={(position && linkToMp4) || canVoteFor ? 6 : 12} md={(position && linkToMp4) || canVoteFor ? 1 : 2} className="d-flex align-items-center justify-content-center">
                <Button variant="link" className="d-inline-block icon-button" onClick={() => {navigateToLyrics()}}>
                    <LyricsIcon className='icon lyrics-icon' />
                    <p className='text-center icon-text'>Lyrics</p>
                </Button>
            </Col>

            <Col onClick={() => {navigate(`/song/${id}#top`)}}>

            </Col>
        </Row>
        </>
    )
}

function BandCard2 ({ bandName, bandImage, songName, position }) {
    return (
        <Row className="band-card m-2 p-2">
            <Col md={3} className="d-flex justify-content-center">
                <img
                    alt={`${bandName} Music Group`}
                    src={bandImage}
                    className="band-image"
                />
            </Col>
            <Col md={6}>
                <Row className='d-flex py-2 justify-content-between'>
                    <Col md={6} className="band-info-container">
                        <h2 className="h3 text-center">
                            {bandName}
                        </h2>
                    </Col>
                    <Col md={6} className="band-info-container">
                        <h2 className="h3 text-center">
                            {songName}
                        </h2>
                    </Col>
                </Row>
                <Row className='py-2'>
                    <Col md={12} className="d-grid">
                        <Button variant='light'>
                            Lyrics
                        </Button>
                    </Col>
                </Row>
                <Row className='py-2'>
                    <Col md={6} className="d-grid">
                        <Button variant='primary'>
                            Listen to MP3
                        </Button>
                    </Col>
                    <Col md={6} className="d-grid">
                        <Button variant='primary'>
                            Watch MP4
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col md={3} className='d-flex justify-content-center align-items-center band-info-text'>
                <h1>{position}</h1>
            </Col>
        </Row>
    )
}

export { BandCard, BandCard2 };
