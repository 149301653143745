import React from "react";
import "./styles.css";

import { TopNavbar, Footer } from "../../components";

function MainLayout ({ children }) {

  return (
  <>
    <div id="linear-gradient-background" className="d-flex flex-column">
      <TopNavbar />
      <div id="fullHeight">
        {children}
      </div>
      <Footer />
    </div>
  </>
  );
}

export default MainLayout;
