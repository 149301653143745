import { db } from './index';
import { doc, setDoc } from 'firebase/firestore'

async function addBand ( id, bandName, songName ) {
    let bandInfo = {
        name: bandName,
        song: songName,
        foreignVotes: 0,
        foreignNames: [],
        localVotes: 0,
        localNames: [],
        judgeVotes: 0,
        judgeNames: []
    }
    try {
        let docRef = doc( db, 'bands', id );
        await setDoc ( docRef, bandInfo );
        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
}

export default addBand;