import React, { useState } from "react";
import { auth } from '../../api'
import { RecaptchaVerifier } from "firebase/auth";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import PhoneInput from 'react-phone-input-2';

import { useUser } from '../../context'
import { useNavigate } from "react-router-dom";

import 'react-phone-input-2/lib/bootstrap.css';
import { MainLayout } from "../../layouts";
import "./styles.css"



function Login () {

    const { login, verify } = useUser()
    let navigate = useNavigate();

    const [phoneNumber, setPhoneNumber] = useState()
    const [country, setCountry] = useState('lb')
    const [loading, setLoading] = useState(false)
    const [phoneDisable, setPhoneDisable] = useState(false)
    const [code, setCode] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    async function onSubmit () {
        setLoading (true);
        if (window.recaptchaVerifier === undefined) {
            window.recaptchaVerifier = new RecaptchaVerifier (
                "sign-in-button",
                { 'size':"invisible"  },
                auth
            )
        }
        const appVerifier = window.recaptchaVerifier;
            try {
                const response = await login(phoneNumber, appVerifier);
                window.confirmationResult = response;
                setLoading(false)
                setPhoneDisable(true);
                setErrorMessage('')
            } catch (err) {
                const errorMessage = err.toString()
                if (errorMessage === 'FirebaseError: Firebase: Error (auth/too-many-requests).') {
                    setErrorMessage('Too many tries, please wait before trying again')
                } else {
                    setErrorMessage('Sorry something went wrong. Try again')
                }
                setLoading(false)
            }
    }

    async function onVerify () {
        const userExists = await verify(code, window.confirmationResult, country)
        if (userExists === 'FirebaseError: Firebase: Error (auth/invalid-verification-code).') {
            console.log('wrong code')
            setErrorMessage('Wrong Code')
        } else if (userExists === undefined) {
            setErrorMessage('Sorry Something Went Wrong');
        } else {
            if (userExists) {
                navigate('/#top');
            } else {
                navigate('/signup#top');
            }
        }
    }

    return (
        <MainLayout>
            <Container className="align-content-center">
                <Row>
                    <Col className="text-center text">
                        <h1>Login</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center text">
                        <h4>Please enter your phone number below:</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center pt-3">
                        <div>
                            <PhoneInput
                                disabled={phoneDisable}
                                className="col"
                                placeholder="+961 76 635 615"
                                country={'lb'}
                                masks={{lb: '.. ... ...'}}
                                defaultMask={ country.formate }
                                value={phoneNumber}
                                onChange={(num, cntry) => {setPhoneNumber(num); setCountry(cntry);} }
                            />
                        </div>
                    </Col>
                </Row>
                { !phoneDisable ?
                <>
                {errorMessage !== '' &&
                <Row>
                    <Col className="d-flex justify-content-center pt-3">
                        <p>{errorMessage}</p>
                    </Col>
                </Row>
                }
                <Row>
                    <Col className="d-flex justify-content-center pt-3">
                        <Button  variant="light" disabled={loading} className="submit-button" onClick={() => {onSubmit()}}>
                            {!loading ? 'Submit' : 'Loading...'}
                        </Button>
                    </Col>
                </Row>
                </>
                :
                <>
                <Row>
                    <Col  className="d-flex justify-content-center pt-4">
                        <h5>Please Enter the Verification Code</h5>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center pt-3">
                        <input className="verify-input" value={code} onChange={(e) => {setCode(e.target.value)}} />
                    </Col>
                </Row>
                {errorMessage !== '' &&
                <Row>
                    <Col className="d-flex justify-content-center pt-3">
                        <p>{errorMessage}</p>
                    </Col>
                </Row>
                }
                <Row>
                    <Col className="d-flex justify-content-center pt-3">
                        <Button variant="light" className="submit-button" onClick={() => {onVerify()}}>
                            Verify
                        </Button>
                    </Col>
                </Row>
                </>
                }
            </Container>
        </MainLayout>
    )
}

export default Login;