import TrinityBaptistChurch from "../../assets/sawtiIlak2021/bandPhotos/TrinityBaptistChurch.jpg";
import SpringofLifeChurch from "../../assets/sawtiIlak2021/bandPhotos/SpringofLifeChurch.jpg";
import RedeemerChurch from "../../assets/sawtiIlak2021/bandPhotos/RedeemerChurch.jpg";
import LifeCenter1 from "../../assets/sawtiIlak2021/bandPhotos/LifeCenter1.jpg";
import ChurchofGodDora from "../../assets/sawtiIlak2021/bandPhotos/ChurchofGodDora.jpg";
import LifeCenter from "../../assets/sawtiIlak2021/bandPhotos/LifeCenter.jpg";

import Andre from '../../assets/sawtiIlak2021/judgePhotos/Andre.jpg';
import Camille from '../../assets/sawtiIlak2021/judgePhotos/Camille.jpg';
import Sabine from '../../assets/sawtiIlak2021/judgePhotos/Sabine.jpg';
import Boutros from '../../assets/sawtiIlak2021/judgePhotos/Boutros.jpg';

const BANDS2021 = [
    {
        id: 0,
        bandName: "Trinity Baptist Church",
        songName: "سكنت بيننا",
        bandImage: TrinityBaptistChurch,
        lyrics:
        <>
            <><br /> دون أن ندركك سكنت بيننا... دماؤك علينا الآن تشفي قلوبنا<br /> دون أن ندركك سكنت بيننا... دماؤك علينا الآن تشفي قلوبنا<br /><br /> طوعاً، ارتضيت أن تأتي<br /> طوعاً، اتيت لكي تشفي<br /> موتاً، قبلت لكي تحيي<br /> ظلماً، اخذت إلى الجلد<br /> صلباً، جرّدت بلا ثوب<br /> ثمّ، دخلت الى القبر<br /><br /> في الظلمة نتبعك تنير دربنا قمت من بين الأموات أتلفت قيودنا<br /> في الظلمة نتبعك تنير دربنا قمت من بين الأموات أتلفت قيودنا<br /><br /> فخراً، ملكت على العرش<br /> مجداً، انتصرت على الموت<br /> حمداً، قضيت على اليأس<br /> دوماً، لا تهدم بل تبني<br /> أنت، إلهي ومختاري<br /> ربي، منقذي من النار<br /><br /> بدمك خلصتنا حملت عارنا... الآن نحن ممتنين... لك تسبيحنا<br /> بدمك خلصتنا حملت عارنا... الآن نحن ممتنين... لك تسبيحنا<br /><br /> سنبقى دائما نقول: انت مسيحنا<br /> ان كان العالم سيزول... باقٍ رجاؤنا</>
        </>,
        linkToMp3: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        linkToMp4: 'zySDhiLYTlw',
        position: '1st Place',
        author: 'الفرد الطويل',
        composer: 'مارك مؤدب',
        year: 2021
    },
    {
        id: 1,
        bandName: "Spring Of Life Church",
        songName: "علّم قلبي كيف يحبّك",
        bandImage: SpringofLifeChurch,
        lyrics: <><br /> علّم قلبي كيف يحبّك<br /> يبقى بحضورك ما يملّ<br /> خلّي يصير بيشبه قلبك<br /> إنت تزيد وهوِّ يقل<br /><br /> خلّي عيني تشوف بعينك<br /> تنظر بمحبّة للناس<br /> تبِحنّية دلُّن وينك<br /> حتّالي ينالوا الخلاص<br /><br /> بحبّك املأني وارويني<br /> بظلّ جناحك خبّيني<br /> وعلّم قلبي كيف يحبّك<br /><br /> ما بدّي اصرف أوقاتي<br /> بهويّة العالم غرقان<br /> لصوبك ودّي كلماتي<br /> يجاوبني صدى الوديان<br /><br /> بدّي بحضورك اتمتّع<br /> احكي وارجع صوتك إسمع<br /> ويعرف قلبي كيف يحبّك</>,
        linkToMp3: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        linkToMp4: 'pdSnCZx9vik',
        position: '2nd Place',
        author: "ميريلا جاباريان",
        composer: "ميريلا جاباريان",
        year: 2021
    },
    {
        id: 2,
        bandName: "Redeemer Baptist Church",
        songName: "كيف فيي ما حبك",
        bandImage: RedeemerChurch,
        lyrics: <><br /> كيف بقدر انسى الوقت<br /> كيف بقدر انسى النظرة<br /> لما توبك لمست وشو حلوة هاللحظة<br /><br /> من مسافة بعيدة<br /> جيت لعندك يسوع<br /> جايي وانا اكيدة<br /> رح تمسح هالدموع<br /><br /> كيف فيي ما حبك<br /> كيف فيي ما اهتم<br /> انت سكنت بروحي<br /> وبعروقي متل الدم<br /> يسوع عنك ما في رجوع<br /> بصرخ بصوت مسموع<br /> شو بحبك<br /><br /> من مسافة بعيدة<br /> جيت لعندك يسوع<br /> جايي وانا اكيدة<br /> رح تمسح هالدموع<br /><br /> كيف فيي ما حبك<br /> كيف فيي ما اهتم<br /> انت سكنت بروحي<br /> وبعروقي متل الدم<br /> يسوع عنك ما في رجوع<br /> بصرخ بصوت مسموع</>,
        linkToMp3: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        linkToMp4: 'E0nTb3-qsJs',
        position: '3rd Place',
        author: "شادي فرهود",
        composer: "شادي فرهود",
        year: 2021
    },
    {
        id: 3,
        bandName: "Church Of God (Life Center)",
        songName: "من السما تركت مجدك",
        bandImage: LifeCenter1,
        lyrics: <><br /> من السما تركت مجدك<br /> تتخلّصنا وتحيينا<br /> كتَبت أسامينا بدمّك<br /> ع صليبك يا فادينا<br /><br /> بصمت وصلا وخشوع<br /> نورك بالعتمة مقشوع<br /> مسحت دموع الموجوع<br /> بيمينك يا راعينا<br /><br /> الجوع استولى ع ولادي<br /> الظلم تفشّى ببلادي<br /> وطني بيصرخ وينادي<br /> غسلنا بدمّك واحمينا<br /><br /> احمينا وغسلنا بدمّك<br /> وما ترفض صلا إبنك<br /> شعبك عم يتوسلّك<br /> تخللي نورك يهدينا<br /><br /> من السما تركت مجدك<br /> تتخلصنا وتحيينا<br /> كتبت أسامينا بدمك<br /> ع صليبك يا فادينا<br /><br /> بصمت وصلا وخشوع<br /> نورك بالعتمة مقشوع<br /> مسحت دموع الموجوع<br /> بيمينك يا راعينا</>,
        linkToMp3: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        linkToMp4: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        position: '',
        author: "طليع خليل",
        composer: "إيلي سماحة",
        year: 2021
    },
    {
        id: 4,
        bandName: "Church of God (Dora)",
        songName: "في ضيق الحياة",
        bandImage: ChurchofGodDora,
        lyrics: <><br /> في ضيق الحياة كنت دوماً لي الصديق<br />  أتي لك تكشف لي الطريق<br />  وحدك يسوع صخرتي<br /> عيناك الهي ترعاني<br /><br />  وفي محضرك أنت وجدت راحتي يسوع محوت خوفي سيدي ومسحت الدموع<br /><br />  كيف لي ان انسى صوتك<br />  كيف لي ان انسى كلامك<br /><br />  كيف أنسى صليبك وفيه أعلن حبك<br /> بذلت كل شيء ربي كي تردني لك وعدك أن تبقى معي الى المنتهى</>,
        linkToMp3: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        linkToMp4: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        position: '',
        author: "غايا داغر",
        composer: "غايا داغر",
        year: 2021
    },
    {
        id: 5,
        bandName: "Church Of God (Life Center)",
        songName: "وتر قلبي بدقلك",
        bandImage: LifeCenter,
        lyrics:<><br /> وتر قلبي بدقلك بيعلنلك عن اشواقي<br /><br /> شو بحبك انا يا ربي والهي<br /> شو بحبك انا يا ربي والهي<br /><br /> كتبتلك هالغنية بلحني يا بيي<br /> كتبتلك هالغنية بلحني يا بيي<br /><br /> تتفرح بهالهدية<br /> تتفرح بهالهدية<br /><br /> ناطر... انا ناطر اسمع صوتك يا يسوع<br /><br /> ناطر... قلبي ناطر يشبع منك يا يسوع<br /> ناطر... قلبي ناطر يشبع منك يا يسوع<br /></>,
        linkToMp3: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        linkToMp4: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        position: '',
        author: "شربل حدشيتي",
        composer: "شربل حدشيتي",
        year: 2021
    },
    {
        id: 6,
        bandName: "Trinity Baptist Church",
        songName: "اريد نعم اريد",
        bandImage: TrinityBaptistChurch,
        lyrics: <><br /> خلّي حياتن تبقى لمجدك<br /> حلّي أوقاتن لوّن ايّامن<br /> خلّي الدفا يمحي أحزانن<br /> باسمك وحدك منطلب يا يسوع<br /><br />أريد نعم اريد... يسوع بارك هالفرحة<br /> يوم عن يوم، حبّن رح يزيد... سعادة مكلّلة بطرحة<br /><br /> بكرا السعادة ترسم عدربن<br /> ببيتن أطفال، اجيال ينمّوها<br /> تبقى الوحيد، مالك عقلبن<br /> لمجدك وحدك بيعيشوا يا يسوع<br /><br /> أريد نعم اريد... يسوع بارك هالفرحة<br /> يوم عن يوم، حبّن رح يزيد... سعادة مكلّلة بطرحة<br /><br /> قولوا امين، الآن وكل حين<br /> علّلوا الأصوات، ها هللويا<br /> يسوع اللي بيشفي، اللي بيحمي اللي بيحيي<br /> وحدة قلبين فرحن عم بيزيد<br /><br /> أريد نعم اريد... يسوع بارك هالفرحة<br /> يوم عن يوم، حبّن رح يزيد... سعادة مكلّلة بطرحة</>,
        linkToMp3: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        linkToMp4: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        position: '',
        author: "الفرد الطويل",
        composer: "الفرد الطويل",
        year: 2021
    },
    {
        id: 7,
        bandName: "Church of God (Dora)",
        songName: "فتعال",
        bandImage: ChurchofGodDora,
        lyrics: <><br/>فتعال تعال تعال<br/> يا خالق الاكوان<br/><br/> تعال بلمستك واحييني<br/> تعال بروحك وقويني<br/> انت الهي لي فاديني<br/>  وعدك ليّ تحميني<br/><br/> قد أكمل على الصليب<br/> جئت لأجلك حبيبي<br/> سفكت دمي فشفاك<br/> من كل ضعف أو ضيقِ<br/><br/>  أنت صخرة الدهور<br/>  أنت ملك الملوك<br/>أنت خلاصي الوحيد<br/> فيك وحدك النجاة<br/> القائم من بين الأموات<br/>  الجالس عن يمين الآب</>,
        linkToMp3: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        linkToMp4: 'https://www.youtube.com/watch?v=jfKfPfyJRdk',
        position: '',
        author: "ساندرا موسان",
        composer: "جورج موسان",
        year: 2021
    }
]

const JUDGES2021 = [
    {
        judgeName: 'Andreh Tawileh',
        judgePicture: Andre,
        judgeDescription: 'Leads a music school and a studio, and has several diplomas in guitar and music'
    },
    {
        judgeName: 'Camille Hanna',
        judgePicture: Camille,
        judgeDescription: 'Professor in the national conservatory, and opera singer',
    },
    {
        judgeName: 'Sabine Jarawan',
        judgePicture: Sabine,
        judgeDescription: 'Piano instructor at the national conservatory',
    },
    {
        judgeName: 'Boutros Wehbe',
        judgePicture: Boutros,
        judgeDescription: 'Singer, songwriter and composer',
    }
]


export { BANDS2021, JUDGES2021 }
